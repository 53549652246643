import styles from './Collapse.module.css'

interface Props {
  title?: React.ReactNode
  size?: 'small' | 'regular' | 'big' | 'minimal'
  defaultShow?: boolean
  borderStyle?: 'border-top' | 'border-bottom' | 'none'
  children: React.ReactNode
  className?: string
}

const Collapse: React.FC<Props> = ({
  title,
  children,
  size = 'regular',
  defaultShow = false,
  borderStyle = 'border-bottom',
  className,
}) => {
  return (
    <details className={`${styles.container} ${className} ${styles[borderStyle]}`} open={defaultShow}>
      <summary className={`${styles.header} ${styles[size]}`}>
        <div className={styles.title}>
          <span>{title}</span>
        </div>
        <div className={styles.icon}>
          <img draggable={false} src={'/lp/assets/icons/arrow.svg'} alt={'V'} />
        </div>
      </summary>
      <div>{children}</div>
    </details>
  )
}

export default Collapse
