import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' viewBox='0 0 24 24' {...props}>
    <g clipPath='url(#a)'>
      <path fill='currentColor' d='m12.173 4.5-1.41 1.41 5.58 5.59H4.173v2h12.17l-5.58 5.59 1.41 1.41 8-8-8-8Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M.173.5h24v24h-24z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
