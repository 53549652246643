// @ts-strict-ignore
import cookie, { CookieSerializeOptions } from 'cookie'

export const COOKIE = {
  LOCATION: 'location',
  NEXT_LOCALE: 'NEXT_LOCALE',
  ADULT: 'adult',
  OPTANON_CONSENT: 'OptanonConsent',
  ALERT_BOX_CLOSED: 'OptanonAlertBoxClosed',
}

const getExistingCookieStrings = (res: any) => {
  const existingHeaders = res.getHeader('Set-Cookie')
  let existingCookieStrings = []

  if (typeof existingHeaders === 'string') {
    existingCookieStrings = [existingHeaders]
  } else if (Array.isArray(existingHeaders)) {
    existingCookieStrings = existingHeaders
  }
  return existingCookieStrings
}

export const ssrAppendCookieStringToHeader = (cookieString: string, res: any) => {
  const existingCookieStrings = getExistingCookieStrings(res)

  res.setHeader('Set-Cookie', [cookieString, ...existingCookieStrings])
}

export const setCookies = (key: string, value: string | number | bigint | boolean | symbol, options: CookieSerializeOptions = {}) => {
  const _opts = {
    path: '/',
    ...options,
  }
  document.cookie = cookie.serialize(key, value.toString(), _opts)
}

export const getCookie = (key: string) => {
  let cookieString
  if (typeof document !== 'undefined') {
    cookieString = document.cookie
  }
  return cookieString && cookie.parse(cookieString)[key] ? cookie.parse(cookieString)[key] : null
}

export const getCookieAsParsedJson = (key: string) => {
  const cookie = getCookie(key)
  return cookie ? JSON.parse(cookie) : null
}

export const deleteCookie = (res: any, key: string, options = {}) => {
  const expiredOptions = { path: '/', expires: new Date(), ...options }
  if (res) {
    res.setHeader('Set-Cookie', cookie.serialize(key, ''), expiredOptions)
  } else {
    document.cookie = cookie.serialize(key, '', expiredOptions)
  }
}
