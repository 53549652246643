'use client'

import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

import { getCookieAsParsedJson, setCookies } from '~/utils/cookies'

import { Button } from '~/components/Button/Button'

import styles from './AdultModal.module.css'

type Props = {
  title: string
  acceptButtonTitle: string
  rejectButtonTitle: string
  rejectButtonRedirectURL?: string
  disclaimer: string
}

export const AdultModal = ({ title, acceptButtonTitle, rejectButtonTitle, rejectButtonRedirectURL = '/', disclaimer }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  useLayoutEffect(() => {
    if (!getCookieAsParsedJson('adult')) {
      setIsOpen(true)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [isOpen])

  const confirmAgeAndClose = useCallback(() => {
    setCookies('adult', true)
    setIsOpen(false)
  }, [])

  return (
    <div className={`${styles.container} ${!isOpen ? styles.hidden : ''}`}>
      <div role='dialog' id='adult-dialog' aria-labelledby='adultDialogLabel' aria-modal='true' className={styles.modal}>
        <h1 className={`heading1 ${styles.title}`} id='adultDialogLabel'>
          {title}
        </h1>
        <Button onClick={confirmAgeAndClose} className={styles.button} size='small'>
          {acceptButtonTitle}
        </Button>
        <Button href={rejectButtonRedirectURL} className={styles.button} size='small' variant='ghost'>
          {rejectButtonTitle}
        </Button>
        <p>{disclaimer}</p>
      </div>
    </div>
  )
}
