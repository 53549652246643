import { AnchorHTMLAttributes, ButtonHTMLAttributes, PropsWithChildren } from 'react'

import ArrowRight from '~/components/Icons/ArrowRight'
import Link from '~/components/Link'

import styles from './Button.module.css'

type BaseProps = {
  size?: 'small' | 'large'
  variant?: 'primary' | 'ghost' | 'outlined'
  className?: string
  withArrow?: boolean
  disabled?: boolean
}

type ButtonProps = BaseProps &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    href?: never
  }

type LinkProps = BaseProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
    href: string
  }

type Props = ButtonProps | LinkProps

export const Button = ({
  href,
  size = 'large',
  variant = 'primary',
  children,
  className,
  withArrow = true,
  disabled,
  ...rest
}: PropsWithChildren<Props>) => {
  const combinedClassName = `${styles.button} ${styles[size] ?? ''} ${styles[variant] ?? ''} ${className ?? ''}`
  const iconRight = withArrow ? <ArrowRight className={styles.iconRight} /> : null

  if (href) {
    const linkProps = rest as LinkProps
    return (
      <Link className={combinedClassName} {...linkProps} href={href}>
        {children}
        {iconRight}
      </Link>
    )
  } else {
    const buttonProps = rest as ButtonProps
    return (
      <button className={combinedClassName} disabled={disabled} type={buttonProps.type} {...buttonProps}>
        {children}
        {iconRight}
      </button>
    )
  }
}
