// @ts-strict-ignore
import * as yup from 'yup'

import { gt as GT } from '~/lib/gt'

export const validationMessages = (gt: typeof GT) => ({
  mixed: {
    required: gt.tp('Error', 'This field is required'),
  },
  string: {
    email: gt.tp('Error', 'Incorrect email format'),
    min: ({ min }: any): string =>
      gt.tnp(
        'Error',
        'This field must be at least {{ count }} character long',
        'This field must be at least {{ count }} characters long',
        min,
        { count: min }
      ),
    max: ({ max }: any): string =>
      gt.tnp(
        'Error',
        'This field must be no longer than {{ count }} character',
        'This field must be no longer than {{ count }} characters',
        max,
        { count: max }
      ),
  },
})

yup.setLocale(validationMessages(GT))

export default yup
