'use client'
import { useEffect } from 'react'

import { ToastViewProps } from './ToastTypes'
import { TOAST_TIMEOUT } from './ToastUtils'
import styles from './ToastView.module.css'

const ToastView = ({ message, type, close }: ToastViewProps) => {
  useEffect(() => {
    const timeout = setTimeout(() => close(), TOAST_TIMEOUT)
    return () => clearTimeout(timeout)
  }, [close])

  return (
    <div className={`${styles.styledToast} ${styles[type]}`}>
      {/* <div className={`${styles.iconWrapper} ${type === 'info' ? styles.iconWrapperInfo : ''}`}>
        <i className={styles.toastIcon}></i>
      </div> */}
      <div className={styles.label}>{message}</div>
      {type === 'success' && (
        <button className={styles.iconWrapper}>
          <i className={styles.xIcon} onClick={close} aria-label='X'>
            X
          </i>
        </button>
      )}
    </div>
  )
}

export default ToastView
