/* eslint-disable @next/next/no-img-element */
'use client'
import React, { useState } from 'react'

import Link from '~/components/Link'
import { gt } from '~/lib/gt'

import styles from './Header.module.css'

type HeaderLink = {
  href: string
  label?: string
  icon?: string
  iconAlt?: string
}

type Logo = {
  src: string
  alt: string
  href?: string
}

type TopBarLinks = {
  left?: HeaderLink[]
  right?: HeaderLink[]
}

type Props = {
  middleLinks?: HeaderLink[]
  rightLinks?: HeaderLink[]
  topBarLinks?: TopBarLinks
  logo?: Logo
  theme?: typeof styles
}

type MenuItemProps = {
  label?: string
  href: string
  theme: typeof styles
  className?: string
  icon?: string
  iconAlt?: string
}

const MenuItem = ({ label, href, theme, className, icon, iconAlt }: MenuItemProps) => {
  const mergedClassName = className ? theme[className] : theme.middleItem
  return (
    <Link key={label} href={href}>
      {icon ? (
        <img className={theme.icon} src={icon} width={22} height={22} alt={iconAlt} draggable={false} />
      ) : (
        <span className={mergedClassName}>{label}</span>
      )}
    </Link>
  )
}

const TopBarMenuItem = ({ label, href, theme }: MenuItemProps) => {
  return (
    <Link className={theme.topBarItem} href={href}>
      <span className={theme.topBarItem}>{label}</span>
    </Link>
  )
}

export const Header = ({
  middleLinks,
  rightLinks,
  topBarLinks,
  logo = { src: '/lp/assets/logo/logo-festival-rw.svg', alt: 'RW' },
  theme = styles,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const mergedTheme = { ...styles, ...theme }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <header className={`container ${mergedTheme.headerContainer}`}>
      {topBarLinks && (
        <nav className={`${mergedTheme.topBarContainer}`}>
          <div className={mergedTheme.topBarLeft}>
            {topBarLinks?.left?.map(({ label, href }) => <TopBarMenuItem key={label} label={label} href={href} theme={mergedTheme} />)}
          </div>
          <div className={mergedTheme.topBarRight}>
            {topBarLinks?.right?.map(({ label, href }) => <TopBarMenuItem key={label} label={label} href={href} theme={mergedTheme} />)}
          </div>
        </nav>
      )}
      <nav className={`${mergedTheme.mainBarContainer}`}>
        <div className={mergedTheme.left}>
          {logo.href ? (
            <Link href={logo.href}>
              <img className={mergedTheme.logo} src={logo.src} alt={logo.alt} draggable={false} />
            </Link>
          ) : (
            <img className={mergedTheme.logo} src={logo.src} alt={logo.alt} draggable={false} />
          )}
        </div>
        <div className={mergedTheme.middle}>
          {middleLinks?.map(({ label, href }) => (
            <MenuItem key={label} label={label} href={href} theme={mergedTheme} className='middleItemAnimation' />
          ))}
        </div>
        <div className={mergedTheme.rightMobile}>
          <MenuItem
            label={'User'}
            href={'/user'}
            icon={'/lp/assets/icons/user.svg'}
            iconAlt={gt.tp('Header', 'User')}
            theme={mergedTheme}
          />
          <div className={mergedTheme.hamburger} onClick={toggleMenu}>
            <img draggable={false} src={'/lp/assets/icons/hamburger.svg'} />
          </div>
        </div>
        <div className={mergedTheme.right}>
          {rightLinks?.map(({ label, href, icon, iconAlt }) => (
            <MenuItem key={label} label={label} href={href} theme={mergedTheme} icon={icon} iconAlt={iconAlt} />
          ))}
        </div>
        {isMenuOpen && (
          <div className={mergedTheme.overlay}>
            <div className={mergedTheme.overlayControls}>
              <img height='32px' className={mergedTheme.logoOverlay} src={logo.src} alt='RW' />
              <button className={mergedTheme.closeButton} onClick={toggleMenu} aria-label={gt.tp('Header', 'Close')}>
                X
              </button>
            </div>
            {middleLinks?.map(({ label, href, icon, iconAlt }) => (
              <MenuItem key={label} label={icon ? iconAlt : label} href={href} theme={mergedTheme} />
            ))}
            {rightLinks?.map(({ label, href, icon, iconAlt }) => (
              <MenuItem key={label} label={icon ? iconAlt : label} href={href} theme={mergedTheme} />
            ))}
          </div>
        )}
      </nav>
    </header>
  )
}
