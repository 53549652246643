'use client'
import { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { NewToastParams, Toast, ToastType } from './ToastTypes'
import ToastView from './ToastView'
import styles from './ToastView.module.css'

export const TOAST_TIMEOUT = 5000

export const ICONS = {
  success: 'circle-check',
  error: 'attention-triangle',
  info: 'info',
}

const ToastContext = createContext<{ add: (params: NewToastParams) => void } | null>(null)
const generateID = (toastsLength: number) => Date.now() + toastsLength + 1

export const ToastProvider = (props: { children: ReactNode | ReactNode[] }) => {
  const [toasts, setToasts] = useState<Array<Toast>>([])
  const [containerSelector, setContainerSelector] = useState('')
  const [mounted, setMounted] = useState(false)
  const toastRef = useRef<HTMLDivElement>(null)
  const querySelect = containerSelector && document.querySelector(containerSelector)

  const close = (id: number) => setToasts(currentToasts => currentToasts.filter(toast => toast.id !== id))

  const contextValue = useMemo(() => {
    const add = (params: NewToastParams) => {
      if (containerSelector !== params.containerSelector) {
        setContainerSelector(params.containerSelector)
        setToasts([
          {
            id: generateID(0),
            message: params.message,
            type: params.type,
            size: params.size,
          },
        ])
      } else {
        setToasts(currentToasts => [
          ...currentToasts,
          {
            id: generateID(currentToasts.length),
            message: params.message,
            type: params.type,
            size: params.size,
          },
        ])
      }

      if (toastRef.current && toastRef.current.getBoundingClientRect().top < 0) {
        toastRef.current.scrollIntoView(false)
      }
    }
    return { add }
  }, [containerSelector])

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  return (
    <ToastContext.Provider value={contextValue}>
      {mounted && containerSelector && querySelect
        ? createPortal(
            <div className={styles.toasts} ref={toastRef}>
              {toasts.map(toast => (
                <ToastView key={toast.id} close={() => close(toast.id)} message={toast.message} type={toast.type as ToastType} />
              ))}
            </div>,
            querySelect
          )
        : null}
      {props.children}
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext)
