import React, { PropsWithChildren } from 'react'

import styles from './Input.module.css'

type Props = {
  className?: string
} & React.InputHTMLAttributes<HTMLInputElement>

export const Input = ({ className, onChange, ...rest }: PropsWithChildren<Props>) => {
  const combinedClassName = `${styles.input} ${className ?? ''}`

  return <input className={combinedClassName} onChange={onChange} {...rest} />
}
