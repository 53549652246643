'use client'

import { useEffect, useRef, useState } from 'react'
import AliceCarousel from 'react-alice-carousel'

import { SliderItem as SliderItemType } from '../HeroSection'

import styles from './Slider.module.css'

type Props = {
  data: SliderItemType[]
  theme?: typeof styles
}

export const SliderItem = ({ mobile, desktop, title }: SliderItemType) => {
  return (
    <picture style={{ height: 'auto', width: '100%' }}>
      <source media='(min-width: 990px)' srcSet={desktop} />
      <img className={styles.image} style={{ margin: 0, objectFit: 'contain' }} src={mobile} alt={title} />
    </picture>
  )
}

export const Slider = ({ data, theme = styles }: Props) => {
  const mergedTheme = { ...styles, ...theme }
  const items = data.map(item => <SliderItem mobile={item.mobile} desktop={item.desktop} key={item.title} title={item.title} />)
  const carouselRef = useRef<AliceCarousel>(null)
  const [active, setActive] = useState(0)

  useEffect(() => {
    carouselRef.current?.slideTo(active)
  }, [active, carouselRef])

  return (
    <div className={mergedTheme.sliderContainer}>
      <div onClick={() => null} className={mergedTheme.dotsContainer}>
        {new Array(items.length).fill(0).map((_, i) => (
          <div
            className={`${active === i ? mergedTheme.activeDot : mergedTheme.dot}`}
            key={i}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              carouselRef.current?.slideTo(active)
              setActive(i)
            }}
          />
        ))}
      </div>
      <AliceCarousel
        items={items}
        ref={carouselRef}
        onResized={() => carouselRef.current?.slideTo(active)}
        mouseTracking
        activeIndex={active}
        autoPlayInterval={3500}
        autoPlay
        autoPlayStrategy={'all'}
        autoWidth
      />
    </div>
  )
}
