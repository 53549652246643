import { call, noop } from '~/utils/function'

import debug from 'debug'

export const getLogger = (namespace: string, level?: 'error' | 'info' | 'log' | 'warn' | 'debug') => {
  const [nsName, nsLevel] = namespace.split(':')
  const logLevel = (level ?? nsLevel ?? 'debug').toLowerCase()
  const isEnabled =
    process.env.NEXT_ENV !== 'production' ||
    (call((global as any).configGet, 'verboseLog', false) ?? false) ||
    ['error', 'critical'].indexOf(logLevel) > -1

  if (!isEnabled) {
    return noop
  }

  const logger = debug(`rc:${nsName}:${logLevel}`)
  return logger
}

debug.enable('rc:*')

const Global = global as any
Global.getLogger = getLogger

export const logger = getLogger
